export const connectionOptions = {
  hosts: {
     domain: 'conference.yellowant.de',
     muc: 'conference.conference.yellowant.de', 
     focus: 'focus.conference.yellowant.de',
  }, 
  externalConnectUrl: 'https://conference.yellowant.de/http-pre-bind', 
  // enableP2P: true, 
  // p2p: { 
  //    enabled: true, 
  //    preferH264: true, 
  //    disableH264: true, 
  //    useStunTurn: true,
  // }, 
  // useStunTurn: true,
  bosh: `https://conference.yellowant.de/http-bind`, // ! if you make your own please omit the "?room=chatmosphere1234" part
  // serviceUrl: `//server.com/http-bind`,
  // websocket: 'wss://meet.jit.si/xmpp-websocket', 
  clientNode: 'http://jitsi.org/jitsimeet', 
}
